@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  color: #e6e6e6;
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
  letter-spacing: 0.7px;
  scroll-behavior: smooth;
}

#root {
  background-color: black;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  border-radius: px;
  background: #3f3f40;
}

body::-webkit-scrollbar-thumb {
  background: #e27101;
}

body::-webkit-scrollbar-thumb:hover {
  background: #cc5f00;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-moz-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-ms-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background: #000
    url(./Assets/stars.png) repeat top
    center;
  z-index: 0;
}

/* .twinkling {
  background: transparent
    url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat
    top center;

  -moz-animation: move-twink-back 200s linear infinite;
  -ms-animation: move-twink-back 200s linear infinite;
  -o-animation: move-twink-back 200s linear infinite;
  -webkit-animation: move-twink-back 200s linear infinite;
  animation: move-twink-back 200s linear infinite;
} */

.App {
  background: #000
    url(./Assets/stars.png) repeat top
    center;
}
