@import "./hero.scss";
@import "./about.scss";
@import "./navbar_footer.scss";
@import "./button.scss";

h1 {
  position: relative;
  font-family: "Bitter", serif;
  font-weight: lighter;
  font-size: 40px;
  letter-spacing: 1.4px;
  color: $heading-color;

  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1rem;
  text-underline-offset: -1rem;
  text-decoration-color: rgba(255, 128, 0, 0.578);
  text-decoration-skip-ink: none; /* works in Firefox & Chrome, not in Safari */
  text-decoration-skip: none; /* works in Safari, not in Firefox & Chrome */

  @media #{$media-mobile} {
    font-size: 30px;
    text-align: center;
    text-decoration-thickness: 0.6rem;
    text-underline-offset: -0.6rem;
  }
}

p {
  line-height: 1.5rem;
  color: $text-color;
  font-size: 14px;
  @media #{$media-mobile} {
    font-size: 13px;
  }
}

i {
  line-height: 1rem;
  color: $text-color;
  font-size: 12px;
  @media #{$media-mobile} {
    font-size: 10px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
