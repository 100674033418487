@import "./variables.scss";

.button-swipe-wrapper {
  width: fit-content;
  margin: 0 auto;
  margin-top: 2rem;
  :active,
  :hover,
  :focus {
    outline: 0 !important;
    outline-offset: 0;
  }
  ::before,
  ::after {
    position: absolute;
    content: "";
  }
}

.btn {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 10px;

  cursor: pointer;
  min-width: 150px;
  background-color: $theme-amber;
}
.btn span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.8rem 3rem;
  transition: 0.3s;
}

/*--- btn-1 ---*/
.btn-1::before {
  background-color: rgb(0, 0, 0);
  transition: 0.3s ease-out;
}
.btn-1 span {
  border: 1.5px solid $theme-amber;
  border-radius: 10px;
  transition: 0.2s 0.1s;
}
.btn-1 span:hover {
  color: rgb(0, 0, 0);
  font-weight: bold;
  transition: 0.2s 0.1s;
}

/* 1.hover-filled-slide-down */
.btn.hover-filled-slide-down::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.btn.hover-filled-slide-down:hover::before {
  height: 0%;
}
